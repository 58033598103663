import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    userProfile: (state) => state.userProfile,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const RemarqueComputed = {
  ...mapState('remarque', {
    Listecommentaire: (state) => state.Listecommentaire,
   }),
  ...mapGetters('remarque', ['Listecommentaire']),
}

export const ServiceComputed = {
  ...mapState('service', {
    ListeService: (state) => state.ListeService,
   }),
  ...mapGetters('service', ['ListeCategorieService','selectCategService','ListeService','selectService']),
}

export const AssuranceComputed = {
  ...mapState('assurance', {
    Assurance: (state) => state.Assurance,
    
   }),
  ...mapGetters('assurance', ['ListeExpertAssure','Assurance','SelectAssurance','ExpertAssurance','ExpertAssurre']),
}

export const GestionnaireComputed = {
  ...mapState('gestionnaire', {
    Assurance: (state) => state.gestionnaire,
   }),
  ...mapGetters('gestionnaire', ['gestionnaire','selectGestionnaire']),
}

export const EtatDossierComputed = {
  ...mapState('etatdossier', {
    EtatDossier: (state) => state.EtatDossier,
   }),
  ...mapGetters('etatdossier', ['EtatDossier','selectEtatDossier']),
}


export const ClientComputed = {
  ...mapState('client', {
    ListeService: (state) => state.ListeService,
   }),
  ...mapGetters('client', ['SelectUser','ListeUser','ListeClient','SelectClient','DetailsClient','totalPagination']),
}

export const FileComputed = {
  ...mapState('file', {
    currentFile: (state) => state.currentFile,
   }),
  ...mapGetters('file', ['ListePochette','currentFile','ListeFile','ListeSearchDevis']),
}

export const SignatureComputed = {
  ...mapState('signature', {
    currentFile: (state) => state.currentFile,
   }),
  ...mapGetters('signature', ['Signature']),
}


export const EmailComputed = {
  ...mapState('email', {
    currentFile: (state) => state.currentFile,
   }),
  ...mapGetters('email', ['ListeHistorique','contentEmail']),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword','Whoami','updateUser','updateEntreprise','updatePassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const servicesMethods = mapActions('service', ['updateCateService','createCategService','deleteCategService','getCategService','createService','getService','updateService','deleteService','SearchService'])
export const clientsMethods = mapActions('client', ['getClientWithPagination','updateEtatClient','createListeUser','getAllUser','createClient','getClient','updateClient','deleteClient','SearchClient','getDetailsClient'])

export const FileMethods = mapActions('file', ['deleteMultiple','uploadImageDossier','getPochete','deletePochette','UpdateCurrentDevisById','searchDevisById','resetListeSearch','searchDevis','updateEtat','convertirToFacture','createFile','generateFile','resetCurrentFile','getDocuments','SearchFile','deleteFile'])

export const EmailMethods = mapActions('email', ['getcontentemail','send_notif','send_email','getHistorique'])
export const SignatureMethods = mapActions('signature', ['createSignature','getSignature'])

export const AssuranceMethods = mapActions('assurance', ['add_exprtAssurre','createExpertAssurance','createExpertAssurre','getExpertAssurance',"getExpertAssurre",'createAssurance','getAssurance','updateAssurance','deleteAssurance'])
export const EtatDossierMethods = mapActions('etatdossier', ['createEtatDossier','getEtatDossier','updateEtatDossier','deleteEtatDossier'])
export const GestionnaireMethods = mapActions('gestionnaire', ['createGestionnaire','getGestionnaire','updateGestionnaire','deleteGestionnaire'])
export const RemarqueMethods = mapActions('remarque', ['deleteCommentaire','getCommentaire','createCommentaire'])


