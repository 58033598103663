import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  ListePochette : [],
  currentUser: null,
  ListeSearchDevis: [],
  ListeFile:[],
  currentFile:{
    "type": null,
    "body": null,
    "tva": null,
    "entreprise_id": null,
    "user_id": null,
    "client_id": null,
    "updated_at": null,
    "created_at": null,
    "id": null
  }  
};

export const mutations = {
    SET_CURRENT_FILE(state, newValue) {
        state.currentFile=newValue;
    },
    RESET_CURRENT_FILE(state) {
        state.currentFile={
            "type": null,
            "body": null,
            "tva": null,
            "entreprise_id": null,
            "user_id": null,
            "client_id": null,
            "updated_at": null,
            "created_at": null,
            "id": null
          }  
    },
    RESET_FILE(state) {
        state.ListeFile=[];
    },
    SET_FILE(state, newValue) {
        state.ListeFile.push(newValue);
    },
    RESET_LISTE_SEARCH(state) {
      state.ListeSearchDevis=[];
    },
    SET_LISTE_SEARCH(state, newValue) {
      state.ListeSearchDevis.push(newValue);
    },
    
    update_FILE(state,value){
        let index = state.ListeFile.findIndex(service => service.id === value.id);
        state.ListeFile.splice(index,1,value)
    },
    delete_FILE(state,value){
        let index = state.ListeFile.findIndex(service => service.id === value.id);
        state.ListeFile.splice(index,1)
    },

    delete_FILE_multiple(state,value){
      let index = state.ListeFile.findIndex(file => file.id === value);
      state.ListeFile.splice(index,1)
  },

    SET_POCHETTE(state, newValue) {
      state.ListePochette.push(newValue);
    },
    delete_POCHETTE(state,value){
      let index = state.ListePochette.findIndex(pochette => pochette.id === value.id);
      state.ListePochette.splice(index,1)
    },
    RESET_POCHETTE(state) {
      state.ListePochette=[];
  },

  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  ListeSearchDevis(state) {
    return state.ListeSearchDevis;
  },
  ListeFile(state) {
    return state.ListeFile;
  },
  currentFile(state) {
    return state.currentFile;
  },
  ListePochette(state) {
    return state.ListePochette;
  },

  

};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  SearchFile({ commit },{nom}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/clients?search="+nom, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            
            commit("RESET_FILE");
          let data=response.data.data
          data.forEach(element => {
            commit("SET_FILE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  // Logs in the current user.
  deleteFile({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/document/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_FILE",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
 
  getDocuments({ commit } , {id}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/document/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_FILE");
 
          let data=response.data.data
          data.forEach(element => {
            commit("SET_FILE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  createFile({ commit }, { data } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/create-pdf", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            console.log('response',response)
          commit("SET_CURRENT_FILE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  

  generateFile({ commit }, { body } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
        let params = {
            'document_id' : state.currentFile.id , 
            'body' : body
        }
      axios
        .post(apiUrl + "api/generate-pdf", params, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            console.log('response',response)
            commit("RESET_CURRENT_FILE");
             // commit("SET_TOKEN_USER",response.data.data.token)
            resolve(response);
        })
        .catch((e) => {
            commit("RESET_CURRENT_FILE");
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  resetCurrentFile({ commit }){
    commit("RESET_CURRENT_FILE");

  },
  convertirToFacture({ commit }, { id,data } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/facture-devis/"+id, {element:data}, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            console.log('response',response)
           
           commit("SET_FILE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  updateEtat({ commit }, { data } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
        
      axios
        .put(apiUrl + "api/document/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            console.log('response',response)
            commit("RESET_CURRENT_FILE");
             // commit("SET_TOKEN_USER",response.data.data.token)
            resolve(response);
        })
        .catch((e) => {
            commit("RESET_CURRENT_FILE");
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  searchDevis({ commit }, { search } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/search-document", {search:search}, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("RESET_LISTE_SEARCH");
         
            let data=response.data.data
            data.forEach(element => {
              commit("SET_LISTE_SEARCH",element);
            });
        
          
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  searchDevisById({ commit }, { id } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/search-document/"+id,    {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("RESET_LISTE_SEARCH");
         
            let data=response.data.data
            data.forEach(element => {
              commit("SET_LISTE_SEARCH",element);
            });
        
          
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  UpdateCurrentDevisById({ commit }, { data,id } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/update-document/"+id, data,   {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("update_FILE",response.data.data);

          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  resetListeSearch({commit}){
     
    commit("RESET_LISTE_SEARCH");
  },
  getPochete({ commit } , {id}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/file-dossier/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_POCHETTE");
 
          let data=response.data.data
          data.forEach(element => {
            commit("SET_POCHETTE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  deletePochette({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/file-dossier/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_POCHETTE",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  uploadImageDossier({ commit }, { data,id,pochette_id } = {}) {
    //delete data['email']
    let formData = new FormData();

    formData.append("image", data);
    formData.append("pochette_id", pochette_id);
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/file-dossier/"+id, formData,   {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("SET_POCHETTE",response.data.data);

          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  deleteMultiple({ commit }, { data } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/delete-document-multiple", {element:data}, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            console.log('response',response)
            data.forEach(element => {
              commit("delete_FILE_multiple",element);
           });
          //  commit("delete_FILE_multiple",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
   


};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
