import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  currentUser: null,
  ListeClient:[],
  SelectClient:[],

  ListeUser:[],
  SelectUser:[],
  totalPagination:0,
  DetailsClient : {
    ref:null,
    nom: null,
    prenom: null,
    email: null,
    rue: null,
    cp: null,
    ville: null,
    tel: null,
    company: null,
    SWIFT_BIC: null,
    IBAN: null,
    num_tva: null,
    etat_dossier : {
      id: null,
      lib : null
    },
    adresse_facturation:null,
    type_sinistre:null,
    date_reception:null,
    civilite:null,

    nom_tierce:null,
    prenom_tierce:null,
    email_tierce:null,
    tel_tierce:null,
    with_tierce:null



  },
};

export const mutations = {
  
    SET_PAGINATION(state, newValue) {
      state.totalPagination=newValue;
    },

    SET_SINGLE_CLIENT(state, newValue) {
      state.DetailsClient=newValue;
    },
    RESET_USER(state) {
      state.ListeUser=[];
      state.SelectUser=[];
      },
    RESET_CLIENT(state) {
    state.ListeClient=[];
    state.SelectClient=[];
    },
    SET_USER(state, newValue) {
      state.ListeUser.push(newValue);
      const select = {text :newValue.name , value : newValue.id}
      state.SelectUser.push(select);
      
  },

    SET_CLIENT(state, newValue) {
        state.ListeClient.push(newValue);
        const select = {text :newValue.nom , value : newValue.id}
        state.SelectClient.push(select);
    },

    update_CLIENT(state,value){
        let index = state.ListeClient.findIndex(service => service.id === value.id);
        state.ListeClient.splice(index,1,value)
    },
    delete_CLIENT(state,value){
        let index = state.ListeClient.findIndex(service => service.id === value.id);
        state.ListeClient.splice(index,1)
    },
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  ListeClient(state) {
    return state.ListeClient;
  },
  SelectClient(state) {
    return state.SelectClient;
  },
  DetailsClient(state) {
    return state.DetailsClient;
  },

  SelectUser(state) {
    return state.SelectUser;
  },
  ListeUser(state) {
    return state.ListeUser;
  },

  totalPagination(state) {
    return state.totalPagination;
  },

};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  SearchClient({ commit },{lib}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/clients?search="+lib, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            
            commit("RESET_CLIENT");
          let data=response.data.data
          data.forEach(element => {
            commit("SET_CLIENT",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  // Logs in the current user.
  deleteClient({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/clients/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_CLIENT",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  updateEtatClient({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/client-etat/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          // console.log("ici response", response.data.data);
          commit("update_CLIENT",response.data.data);
          commit("SET_SINGLE_CLIENT",response.data.data)
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  updateClient({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/client/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          // console.log("ici response", response.data.data);
          commit("update_CLIENT",response.data.data);
          commit("SET_SINGLE_CLIENT",response.data.data)
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  getClient({ commit }) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/clients", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_CLIENT");
 
          let data=response.data.data.data
          data.forEach(element => {
            commit("SET_CLIENT",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  getClientWithPagination({ commit },{per_page,page}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/clients?per_page="+per_page+"&page="+page, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("RESET_CLIENT");
          commit("SET_PAGINATION",response.data.data.total);

          let data=response.data.data.data
          
          data.forEach(element => {
            commit("SET_CLIENT",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },


  

  createClient({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/clients", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
       
          commit("SET_CLIENT",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getDetailsClient({ commit }, { id } = {}) {

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/clients/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          let data=response.data.data
         
            commit("SET_SINGLE_CLIENT",data);
          
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  getAllUser({ commit }) {
    //delete data['email']

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/all-user", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_USER");
 
          let data=response.data.data
          data.forEach(element => {
           
            commit("SET_USER",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  createListeUser({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/user-store", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            

          commit("SET_USER",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  

  
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
