import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  
  Signature : {
    body: ""
  },
};

export const mutations = {

    SET_SINGLE_CLIENT(state, newValue) {
      state.DetailsClient=newValue;
    },

    RESET_SIGNATURE(state) {
        state.Signature={
            body:""
        };
     },
    SET_SIGNATURE(state, newValue) {
        state.Signature=newValue;
        
    },

   
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  Signature(state) {
    return state.Signature;
  },
 


};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

 

  
  
  
  createSignature({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/signature", {body:data}, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            
            commit("RESET_SIGNATURE");

          commit("SET_SIGNATURE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getSignature({ commit }) {

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/signature", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          let data=response.data.data

         if(data.length !=[]){
            commit("SET_SIGNATURE",data);

        }else{
           
            commit("RESET_SIGNATURE");

         }

           
          
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  }

  
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
