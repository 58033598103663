import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  
  EtatDossier : [],
  selectEtatDossier: []
};

export const mutations = {

    update_EtatDossier(state,value){
        let index = state.EtatDossier.findIndex(ass => ass.id === value.id);
        state.EtatDossier.splice(index,1,value)
    },
    delete_EtatDossier(state,value){
        let index = state.EtatDossier.findIndex(ass => ass.id === value.id);
        state.EtatDossier.splice(index,1)
    },
    RESET_EtatDossier(state) {
        state.EtatDossier=[];
        state.selectEtatDossier=[];

     },
    ADD_EtatDossier(state, newValue) {
        state.EtatDossier.push(newValue);
        const select = {text :newValue.lib , value : newValue.id}
        state.selectEtatDossier.push(select);

    },

    SET_EtatDossier(state, newValue) {
        state.EtatDossier= newValue;
    },
   
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  EtatDossier(state) {
    return state.EtatDossier;
  },
  selectEtatDossier(state) {
    return state.selectEtatDossier;
  },

  
 


};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

 

  
  
  
  createEtatDossier({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/etat-dossier", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            

          commit("ADD_EtatDossier",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getEtatDossier({ commit }) {

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/etat-dossier", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_EtatDossier");

          let data=response.data.data

          data.forEach(element => {
            commit("ADD_EtatDossier",element);
          });
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  updateEtatDossier({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/etat-dossier/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          
          commit("update_EtatDossier",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  deleteEtatDossier({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/etat-dossier/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_EtatDossier",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
