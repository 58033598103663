<template>
  <div id="app">
    <!--Replace with your tailwind.css once created-->
    <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700" rel="stylesheet" />
   
      
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { authMethods } from "@/state/helpers";
 

export default {
 
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted(){
   
    this.Whoami()
  },
   
  methods : {
    ...authMethods
  }
};
</script>

<style  >

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>