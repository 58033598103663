import { getFirebaseBackend } from "../../helpers/firebase/authUtils";
import { apiUrl } from "../../../config";
import axios from "axios";
import router from '../../router/index'

export const state = {
  currentUser: null,
  userProfile: {
    name: null,
    prenom:null,
    adresse: null,
    cp: null,
    email: null,
    entreprise: {
      IBAN: null,
      SWIFT_BIC: null,
      cp: null,
      email: null,
      id: null,
      lib: null,
      logo: null,
      num_tva: null,
      pays: null,
      rue: null,
      siret: null,
      tel: null,
      ville: null,
    },
    entreprise_id: null,
    id: null,

    tel: null,
    token: null,
    ville: null,
  },
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.userProfile = newValue;
  },
  SET_TOKEN_USER(state, newValue) {
    localStorage.setItem("auth", newValue);
  },
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  currentUser(state) {
    return state.currentUser;
  },
  user(state) {
    return state.user;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  updatePassword({ commit }, { data } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/update-password", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("NOTHING",response);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  // Logs in the current user.
  updateEntreprise({ commit }, { data,id } = {}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/entreprise/"+id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          console.log("ici response", response);
          commit("NOTHING");
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  updateUser({ commit }, { data } = {}) {
    //delete data['email']

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/user-update", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          console.log("ici response", response);
          commit("NOTHING");
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  Whoami({ commit }) {
    return new Promise((resolve) => {
      axios
        .get(apiUrl + "api/user-details", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("SET_CURRENT_USER", response.data.data.user);
          resolve(true);
        })
        .catch((e) => {
          
          localStorage.clear();
          router.push('/login');
          console.log(e);
          //reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  // Logs in the current user.
  logIn({ commit }, { email, password } = {}) {
    let params = {
      email: email,
      password: password,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/login", params, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((response) => {
          commit("SET_CURRENT_USER", response.data.data.user);
          commit("SET_TOKEN_USER", response.data.data.token);
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("SET_CURRENT_USER", null);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      getFirebaseBackend()
        .logout()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response) => {
        const user = response;
        commit("SET_CURRENT_USER", user);
        return user;
      });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response) => {
        const message = response.data;
        return message;
      });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
    const user = getFirebaseBackend().getAuthenticatedUser();
    commit("SET_CURRENT_USER", user);
    return user;
  },
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
