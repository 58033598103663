import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  currentUser: null,
  Listecommentaire:[],
   
};

export const mutations = {
    
    
    RESET_COMMENTAIRE(state) {
        state.Listecommentaire=[];
    },
    SET_COMMENTAIRE(state, newValue) {
        state.Listecommentaire.push(newValue);
    },

    update_COMMENTAIRE(state,value){
        let index = state.Listecommentaire.findIndex(service => service.id === value.id);
        state.Listecommentaire.splice(index,1,value)
    },
    delete_COMMENTAIRE(state,value){
        let index = state.Listecommentaire.findIndex(service => service.id === value.id);
        state.Listecommentaire.splice(index,1)
    },
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  
  Listecommentaire(state) {
    return state.Listecommentaire;
  },
   
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

 

  // Logs in the current user.
  deleteCommentaire({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/comments/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_COMMENTAIRE",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
 
  getCommentaire({ commit } , {id}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/comments/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_COMMENTAIRE");
            console.log('commentaire',response)
          let data=response.data.data
          data.forEach(element => {
            commit("SET_COMMENTAIRE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  createCommentaire({ commit }, { data } = {}) {
    //delete data['email']
   
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/comments", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
             
          commit("SET_COMMENTAIRE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  

   

  
   

 

};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
