import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
    ListeHistorique:[],
    contentEmail : null
};

export const mutations = {
    SET_HISTORIQUE(state, newValue) {
        state.ListeHistorique.push(newValue);
    },
    RESET_HISTORIQUE(state) {
        state.ListeHistorique=[]
    },
    SET_CONTENTEMAIL(state, newValue) {
      state.contentEmail = (newValue);
  },

  NOTHING() {},
};

export const getters = {
   
    ListeHistorique(state) {
        return state.ListeHistorique;
    },
    contentEmail(state) {
      return state.contentEmail;
    },

};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },
 

 
  getHistorique({ commit },{id}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/historique/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_HISTORIQUE");
          let data=response.data.data
          data.forEach(element => {
            commit("SET_HISTORIQUE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  
 
  send_email({ commit }, { id,data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/send-mail/"+id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
       
          commit("NOTHING",response);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },


  send_notif({ commit }, { data,dossier } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/send-notif", {data:data,dossier:dossier}, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
       
          commit("NOTHING",response);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getcontentemail({ commit },{id}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/get-content-email/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          
          let data=response.data.data

          
            commit("SET_CONTENTEMAIL",data);
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },


 

  
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
