 import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  currentUser: null,
  ListeService:[] ,
  selectService : [] ,
  selectCategService : [],
  ListeCategorieService:[] ,

};

export const mutations = {
   
  RESET_CATEG_SERVICE(state) {
 
    state.selectCategService=[];
    state.ListeCategorieService=[];
  },

  RESET_SERVICE(state) {
    state.ListeService=[];
    state.selectService=[];
   },
  SET_CATEG_SERVICE(state, newValue) {
    state.ListeCategorieService.push(newValue);
    const select = {text :newValue.lib , value : newValue.id}
    state.selectCategService.push(select);
  },
  update_CATEG_SERVICE(state,value){
    let index = state.ListeCategorieService.findIndex(service => service.id === value.id);
    state.ListeCategorieService.splice(index,1,value)
  },
  delete_CATEG_SERVICE(state,value){
    let index = state.ListeCategorieService.findIndex(service => service.id === value.id);
    state.ListeCategorieService.splice(index,1)
  },

  SET_SERVICE(state, newValue) {
    state.ListeService.push(newValue);
    const select = {text :newValue.lib , value : newValue.id, prix:newValue.prix_service,unite:newValue.type_unite}
    state.selectService.push(select);
  },

  update_SERVICE(state,value){
    let index = state.ListeService.findIndex(service => service.id === value.id);
    state.ListeService.splice(index,1,value)
  },
  delete_SERVICE(state,value){
    let index = state.ListeService.findIndex(service => service.id === value.id);
    state.ListeService.splice(index,1)
  },
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  ListeService(state) {
    return state.ListeService;
  },
  selectService(state) {
    return state.selectService;
  },
  
  ListeCategorieService(state) {
    return state.ListeCategorieService;
  },
  selectCategService(state) {
    return state.selectCategService;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  SearchService({ commit },{lib}) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/services?search="+lib, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            
            commit("RESET_SERVICE");
          let data=response.data.data
          data.forEach(element => {
            commit("SET_SERVICE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  // Logs in the current user.
  deleteService({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/services/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_SERVICE",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  updateService({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/service/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          console.log("ici response", response);
          commit("update_SERVICE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  getService({ commit }) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/services", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_SERVICE");
 
          let data=response.data.data
          data.forEach(element => {
            commit("SET_SERVICE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  createService({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/services", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          console.log("ici response", response);
          commit("SET_SERVICE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },


  getCategService({ commit }) {
    //delete data['email']
  
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/service-categorie", {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_CATEG_SERVICE");
 
          let data=response.data.data
          data.forEach(element => {
            commit("SET_CATEG_SERVICE",element);
          });
        
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  
  deleteCategService({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/service-categorie/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_CATEG_SERVICE",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  createCategService({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/service-categorie", data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          console.log("ici response", response);
          commit("SET_CATEG_SERVICE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },


  updateCateService({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/service-categorie/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          
          commit("update_CATEG_SERVICE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },


  

 
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
