import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  ListeExpertAssure : [],
  ExpertAssurance : {
     cabinet : null,
     nom : null ,
     prenom : null,
     email : null,
     rue : null,
     cp : null,
     ville:null, 
     tel:null, 
     numero_adresse:null,
     ref:null,
     nom_assistante:null,
      prenom_assistante:null,
      email_assistante:null,
      tel_assistante:null,
  },
  ExpertAssurre : {
    cabinet : null,
    nom : null ,
    prenom : null,
    email : null,
    rue : null,
    cp : null,
    ville:null, 
    tel:null, 
    numero_adresse:null,
    ref:null,
    nom_assistante:null,
    prenom_assistante:null,
    email_assistante:null,
    tel_assistante:null,
    role:null,
    error:false,
    error_assistante:false
  },
  Assurance : {
    lib: null,
    ref: null,
    rue: null,
    email: null,
    cp: null,
    ville: null,
    tel: null,
    numero_adresse:null,
    police:null
  },
  SelectAssurance : []
};

export const mutations = {

    update_ASSURANCE(state,value){
        let index = state.Assurance.findIndex(ass => ass.id === value.id);
        state.Assurance.splice(index,1,value)
    },
    delete_ASSURANCE(state,value){
        let index = state.Assurance.findIndex(ass => ass.id === value.id);
        state.Assurance.splice(index,1)
    },
    RESET_ASSURANCE(state) {
        state.Assurance=[];
        state.SelectAssurance=[];
     },
    ADD_ASSURANCE(state, newValue) {
        state.Assurance=(newValue);
        // const select = {text :newValue.lib , value : newValue.id}
        // state.SelectAssurance.push(select);
    },

    SET_ASSURANCE(state, newValue) {
        state.Assurance= newValue;
        
    },
    SET_EXPERT_ASSURANCE(state, newValue) {
      state.ExpertAssurance= newValue;
    },

    RESET_EXPERT_ASSURANCE(state) {
      state.ExpertAssurance={
        cabinet : null,
        nom : null ,
        prenom : null,
        email : null,
        rue : null,
        cp : null,
        ville:null, 
        tel:null, 
     }
    },
    RESET_EXPERT_ASSURRE(state) {
      state.ListeExpertAssure=[]
      state.ExpertAssurre={
        cabinet : null,
        nom : null ,
        prenom : null,
        email : null,
        rue : null,
        cp : null,
        ville:null, 
        tel:null, 
     }
    },
    SET_EXPERT_ASSURRE(state, newValue) {
      //state.ExpertAssurre= newValue;
      state.ListeExpertAssure.push(newValue)
    },
    
    update_EXPERT_ASSURRE(state,value){
      let index = state.ListeExpertAssure.findIndex(ass => ass.id === value.id);
      state.ListeExpertAssure.splice(index,1,value)
  },

   
  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  Assurance(state) {
    return state.Assurance;
  },
  ExpertAssurance(state) {
    return state.ExpertAssurance;
  },
  ExpertAssurre(state) {
    return state.ExpertAssurre;
  },
  SelectAssurance(state) {
    return state.SelectAssurance;
  },
  ListeExpertAssure(state) {
    return state.ListeExpertAssure;
  },
  
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

 

  
  
  
  createAssurance({ commit }, { id,data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/assurance/"+id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            

          commit("ADD_ASSURANCE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getAssurance({ commit },{id}) {

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/assurance/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_ASSURANCE");

          let data=response.data.data

          console.log('ici data assurance=',data)
            commit("ADD_ASSURANCE",data);
          
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  updateAssurance({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/assurance/"+data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          
          commit("update_ASSURANCE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  deleteAssurance({ commit }, { data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/assurance/"+data.id ,  {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(( ) => {
           
          commit("delete_ASSURANCE",data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getExpertAssurance({ commit },{id}) {

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/expert-assurance/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_EXPERT_ASSURANCE");

          let data=response.data.data

        
            commit("SET_EXPERT_ASSURANCE",data);
          
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  }, 
  getExpertAssurre({ commit },{id}) {

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/expert-assure/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            commit("RESET_EXPERT_ASSURRE");

          let data=response.data.data

          data.forEach(element => {
            commit("SET_EXPERT_ASSURRE",element);
          });
     
          
          
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  createExpertAssurance({ commit }, { id,data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/expert-assurance/"+id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            

          commit("SET_EXPERT_ASSURANCE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  createExpertAssurre({ commit }, { id,data } = {}) {
    //delete data['email']
     
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/expert-assure/"+id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
            

          commit("update_EXPERT_ASSURRE",response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  add_exprtAssurre({commit}){

     
    const newAsurer={
      cabinet : null,
      nom : null ,
      prenom : null,
      email : null,
      rue : null,
      cp : null,
      ville:null, 
      tel:null, 
      numero_adresse:null,
      ref:null,
      nom_assistante:null,
      prenom_assistante:null,
      email_assistante:null,
      tel_assistante:null,
      id:null,
      role:null
    }
    commit("SET_EXPERT_ASSURRE",newAsurer);
     
  }
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
