import { apiUrl } from "../../../config";
import axios from "axios";

export const state = {
  gestionnaire: {
    nom: null,
    prenom: null,
    email: null,
    tel: null,
  },
  selectGestionnaire: [],
};

export const mutations = {
  update_Gestionnaire(state, value) {
    let index = state.gestionnaire.findIndex((ass) => ass.id === value.id);
    state.gestionnaire.splice(index, 1, value);
  },
  delete_Gestionnaire(state, value) {
    let index = state.gestionnaire.findIndex((ass) => ass.id === value.id);
    state.gestionnaire.splice(index, 1);
  },
  RESET_Gestionnaire(state) {
    state.gestionnaire = [];
    state.selectGestionnaire=[];
  },
  ADD_Gestionnaire(state, newValue) {
    state.gestionnaire.push(newValue);
    const select = {
      text: newValue.nom + " " + newValue.prenom,
      value: newValue.id,
    };
    state.selectGestionnaire.push(select);
  },

  SET_Gestionnaire(state, newValue) {
    state.gestionnaire = newValue;
  },

  NOTHING() {},
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  gestionnaire(state) {
    return state.gestionnaire;
  },
  selectGestionnaire(state) {
    return state.selectGestionnaire;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  createGestionnaire({ commit }, { id,data } = {}) {
    //delete data['email']

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "api/gestionnaire-assurance/"+id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("SET_Gestionnaire", response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },

  getGestionnaire({ commit },{id}) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "api/gestionnaire-assurance/"+id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("RESET_Gestionnaire");

          let data = response.data.data;
          commit("SET_Gestionnaire", data);
          // data.forEach((element) => {
          //   commit("SET_Gestionnaire", element);
          // });
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  updateGestionnaire({ commit }, { data } = {}) {
    //delete data['email']

    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl + "api/gestionnaire-assurance/" + data.id, data, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          commit("update_Gestionnaire", response.data.data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
  deleteGestionnaire({ commit }, { data } = {}) {
    //delete data['email']

    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl + "api/gestionnaire-assurance/" + data.id, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then(() => {
          commit("delete_Gestionnaire", data);
          // commit("SET_TOKEN_USER",response.data.data.token)
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
          //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
        });
    });
  },
};

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.localStorage.setItem(key, JSON.stringify(state))
// }
